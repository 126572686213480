import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const AdditionalIntegrationsSupportHours = () => (
  <Layout>
    <SEO title="Additional Integrations Support Hours Addons" />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>Additional Integrations Support Hours | $115 CAD/hour</h2>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <h5 className="heading">INCLUDED:</h5>
            <ul>
              <li>Troubleshooting/support/bug form</li>
              <li>
                Hourly integrations support, troubleshooting, research &
                updating
              </li>
            </ul>

            <p className="text-small">
              <i>
                Note: This package does not include the cost of automated
                marketing software or CRM or paid integration plugins or tools.
                We do not charge a markup on those, and they will need to be
                purchased directly by the client, using their preferred payment
                method.
              </i>
            </p>
            <p className="text-red">
              This service can only be purchased as an add-on to one of our
              other monthly email packages. Please return to the email packages
              page and select this add-on during the checkout process.
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default AdditionalIntegrationsSupportHours;
